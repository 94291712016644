<template>
	<div id="intention">
		<Header2022 :navbg='true'></Header2022>
		<div class="follow">
			<div class="title">提交区域选择，我们将为您<i>建设满意的Smart0住宅！</i></div>
			<div class="follow-wrap">
				<div class="information">
						<div class="desc">Smart0采用全国创新的落地方式，你的家建在哪里由您决定！</div>
						<div class="smart0-flow">
							<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/fang/smart0/smart0-flow.png" alt="">
						</div>
						<div class="desc">提交表单后第一时间，我们将会安排品牌顾问联系您，为您提供专属服务，解答您对Smart0的一切疑问。</div>
						<div class="info-item">
							<div class="label">地区选择<i>⁎</i></div>
							<el-cascader v-model="value" :options="regionList" @change="handleChange" :props="props"
								clearable filterable ></el-cascader>
						</div>
						<div class="info-item">
							<div class="label">联系姓名<i>⁎</i></div>
							<div class="value">
								<el-input v-model="name" placeholder="请输入真实姓名" maxlength="10"></el-input>
							</div>
						</div>
						<div class="info-item">
							<div class="label">联系方式<i>⁎</i></div>
							<div class="value">
								<el-input v-model="mobile" placeholder="请输入手机号" type="tel" maxlength="11"></el-input>
							</div>
						</div>
						<div class="info-item">
							<div class="label">电子邮箱</div>
							<div class="value">
								<el-input v-model="email" placeholder="请输入邮箱号" type="email"></el-input>
							</div>
						</div>
						<div class="btn-wrap">
							<el-button class="btn" @click="submitTap">立即提交</el-button>
							<div class="select-wrap">
								<div class="select" @click="selectTap">
									<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/duihao1.png"
										mode="widthFix" v-if="protocolChecked" />
									<img src="https://xcx-1257793586.cos.ap-beijing.myqcloud.com/linli/images/duihao2.png"
										mode="widthFix" v-else />
								</div>
								<div class="document">
									<i @click="intentTap">意向提交及隐私说明</i>
								</div>
							</div>
							<el-link type="primary" @click="followTap">我的意向<i
									class="el-icon-view el-icon-arrow-right"></i></el-link>
						</div>
						<div class="tips">·通过点击「提交订阅」按钮，即表示您同意西安小院科技所提供的信息，西安小院科技可按照客户隐私声明收集、使用、处理这些信息。</div>
						<div class="tips">·西安小院科技会将您的关注、订单更新状态和所有服务详情发送至您的邮箱及手机，请确保您的手机号码与电子邮箱畅通。</div>
					</div>
					<div class="house-wrap">
						<div class="house-list" v-if="houseList && houseList.length>0">
							<div class="house-item" v-for="(item,index) in houseList" :key="index">
								<div class="image">
									<img :src="item.image_max" alt="">
								</div>
								<div class="reserve">
									<i>{{item.name}}</i>
									<el-button class="blackbtn" @click="reserveTap(item.category_id)">立即预定</el-button>
								</div>
							</div>
						</div>
						<div class="house-img" v-else>
							<img  src="../../../assets/images/smart0/follow-house.png" alt="" >
						</div>
					</div>
				</div>
			</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	export default {
		components: {
			Header2022,
			Footer2022,
		},
		data() {
			return {
				name: '',
				mobile: '',
				email: '',
				houseList: [],
				value: [],
				regionList: [],
				props: {
					value: "id",
					label: "name",
					children: "items",
				},
				protocolChecked: false,
				pdf:'',
			}
		},
		computed: {
			...mapState(['ocApi', 'user_info', 'houseApi']),
		},
		created() {
			this.getRegionList();
			this.getAgreement();
		},
		activated() {
			this.value=[];
			this.houseList=[];
			if (this.user_info) {
				this.mobile = this.user_info.telephone;
				this.name = this.user_info.fullname;
			}
		},
		methods: {
			getAgreement: function() {
				this.$axios.get(this.ocApi + 'book/banner?name=live_user_service_privacy')
					.then(res => {
						if (res.data.data.status == 1) {
							this.pdf = res.data.data.images[0].link;
						} else {
							this.$message.error(res.data.message);
						}
					})
			},
			selectTap(){
				this.protocolChecked = !this.protocolChecked;
			},
			intentTap(){
				window.open(this.pdf);
			},
			reserveTap(id) {
				this.$router.push({
					name: 'houseConfig',
					params: {
						id,
						houseType: 'smart0'
					}
				});
			},
			followTap() {
				this.$router.push({
					name: 'appointment',
					params: {
						tagName:'intention',
					}
				});
			},
			handleChange(value) {
				this.gethouseList(value.slice(-1));
			},
			gethouseList(area_id) {
				this.$axios.get(this.houseApi + 'house/area_house_project_list',{
						params: {
							type:'smart0',
							area_id,
						}
					})
					.then(res => {
						this.houseList = res.data.data.homes_projects;
					})
					.catch(error => {
						this.$message.error(error.response.data.message);
					});
			},
			submitTap() {
				if (!this.protocolChecked) {
					this.$message({
						message: "请阅读并同意隐私说明！",
						type: 'warning',
						offset: 100,
						center: true,
						duration: 5000
					});
					return false;
				}
				if (!this.name || !this.mobile || this.value.length < 1) {
					this.$message({
						message: "请将带*号的信息填写完整！",
						type: 'warning',
						offset: 100,
						center: true,
						duration: 5000
					});
					return false;
				}
				var customer_id;
				if(this.user_info && this.user_info.customer_id){
					customer_id=this.user_info.customer_id
				}
				this.$axios.post(this.ocApi + 'intentionCollection/registration', {
						name: this.name,
						telephone: this.mobile,
						email: this.email,
						zone_id: this.value[0],
						city_id: this.value[1],
						county_id: this.value[2],
						customer_from: 'website_pc',
						customer_id,
					})
					.then(res => {
						if (res.data.status == 1) {
							this.$confirm(res.data.message, '提交成功', {
								type: 'success',
								center: true,
								showConfirmButton: false,
								showCancelButton: false,
							}).then(() => {
								console.log('confirm')
							}).catch(() => {
								this.$router.replace({
									path: 'smart0',
								});
							});
						} else {
							this.$message.error(res.data.message);
						}
					})
					.catch(error => {
						this.$message.error(error.response.data.message);
					});
			},
			getRegionList() {
				this.$axios.get(this.ocApi + 'regions/get_zone_and_city')
					.then(res => {
						if (res.data.countries[0].status == 1) {
							this.regionList = res.data.countries[0].items;
							// 处理区域为空数据
							for (let arr of this.regionList) {
								arr.items.forEach((item, index) => {
									if (item.items.length < 1) {
										Reflect.deleteProperty(item, 'items');
									}
								})
							}

						} else {
							this.$message.error(res.data.message);
						}
					})
					.catch(error => {
						this.$message.error(error.response.data.message);
					});
			}
		}
	}
</script>

<style lang="scss">
	#intention {
		.follow {
			padding: 150px 4% 0;
			box-sizing: border-box;
			width: 100%;
			.follow-wrap{
				display: flex;
				justify-content: space-between;
			}
			.house-img {
				margin-top: 40px;
				img{
					width: 100%;
					height: auto;
				}
			}
			.title {
				font-size: 36px;
				line-height: 38px;
				i{
					font-size: 38px;
					font-weight: 800;
				}
			}
			.information {
				margin-bottom: 30px;
				max-width: 32%;
				
				.smart0-flow{
					height: 34px;
					margin: 0px 0 45px;
					img{
						width: 100%;
					}
				}
				.desc {
					line-height: 20px;
					margin: 15px 0;
					font-weight: 800;
				}
			
				.el-input__inner {
					width: 480px;
					border-radius: 10px;
				}
			
				.info-item {
					margin-bottom: 30px;
					font-size: 22px;
			
					.label {
						font-size: 22px;
						line-height: 22px;
						margin-bottom: 13px;
					}
			
					.right {
						font-size: 18px;
					}
			
					i {
						color: #FF0000;
					}
				}
				.btn-wrap{
					width: 80%;
					text-align: center;
					.btn {
						border: 1px solid #000000;
						color: #000000;
						cursor: pointer;
						width: 258px;
						height: 42px;
						margin-bottom: 10px;
						border-radius: 20px;
					}
				}
				.el-link {
					margin-bottom: 10px;
				}
				.select-wrap{
					display: flex;
					align-items: center;
					margin: 0px 0 10px 0;
					font-size: 14px;
					justify-content: center;
				}
				.document{
					text {
						color: #0de699;
					}
				}
				.select {
					width: 20px;
					height: 20px;
					border-radius: 100%;
					overflow: hidden;
					margin-right: 10px;
					img {
						width: 100%;
					}
				}
				.tips {
					font-size: 12px;
					font-weight: 200;
					color: #595959;
					line-height: 20px;
					margin-bottom: 5px;
				}
			}
			
			@media only screen and (max-width:1440px) {
				.information {
					.el-input__inner {
						width: 360px;
						border-radius: 10px;
					}
					.info-item {
						margin-bottom: 25px;
						font-size: 20px;
					}
				}
			}
			@media only screen and (max-width:1280px) {
				.information {
					.el-input__inner {
						width: 320px;
						border-radius: 10px;
					}
				}
			}
			.house-wrap {
				max-width: 58%;
				flex:1;
				.house-list {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					
					.house-item {
						width: 44%;
						margin-bottom: 40px;
						.image{
							img {
								width: 100%;
								height: 23.8vh;
								border-radius: 8px;
							}
						}
						.reserve {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 20px;
			
							i {
								font-size: 24px;
							}
			
							.el-button {
								width: 28%;
								height: 46px;
								background: linear-gradient(90deg, #01CDF2 0%, #0FE993 100%);
								border-radius: 24px;
								font-size: 18px;
								color: #FFFFFF;
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
			
						@media only screen and (max-width:1680px) {
							.reserve {
								i {
									font-size: 22px;
								}
			
								.el-button {
									height: 42px;
									border-radius: 24px;
									font-size: 18px;
								}
							}
							.image{
								img {
									height: 21.5vh;
								}
							}
						}
			
						@media only screen and (max-width:1440px) {
							.reserve {
								i {
									font-size: 20px;
								}
			
								.el-button {
									height: 36px;
									border-radius: 24px;
									font-size: 14px;
								}
							}
						}
			
						@media only screen and (max-width:1280px) {
							.reserve {
								i {
									font-size: 16px;
								}
			
								.el-button {
									height: 34px;
									border-radius: 24px;
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>